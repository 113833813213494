@import url('https://fonts.googleapis.com/css?family=Satisfy&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #71aec0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; 
  background-image: url("regenboog2.png"),url("miguelwolkje.png");
  background-size: cover;
  background-position-y: center;
}

img {
  transform: translate(50px,50px);
}
p {
  transform: translateY(25px);
  font-family: 'Satisfy', cursive;
  font-size: 25px;
  text-shadow: lightcoral;color: #FFFFFF;

  text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #FF2D95 0px 0px 20px, #FF2D95 0px 0px 30px, #FF2D95 0px 0px 40px, #FF2D95 0px 0px 50px, #FF2D95 0px 0px 75px;
  color: #FFFFFF;
}

p + p {
  transform: translateY(0px);
  margin: 0;
}


.App-link {
  color: #09d3ac;
}
