@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #71aec0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; 
  background-image: url(/static/media/regenboog2.d8aa877d.png),url(/static/media/miguelwolkje.ad7133e8.png);
  background-size: cover;
  background-position-y: center;
}

img {
  -webkit-transform: translate(50px,50px);
          transform: translate(50px,50px);
}
p {
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
  font-family: 'Satisfy', cursive;
  font-size: 25px;
  text-shadow: lightcoral;color: #FFFFFF;

  text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #FF2D95 0px 0px 20px, #FF2D95 0px 0px 30px, #FF2D95 0px 0px 40px, #FF2D95 0px 0px 50px, #FF2D95 0px 0px 75px;
  color: #FFFFFF;
}

p + p {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  margin: 0;
}


.App-link {
  color: #09d3ac;
}

